$link-color: white;
$link-decoration: none;
$link-font-size: 24px;
$link-font-weight: bold;
$link-padding: 2px 2px;
$link-border-radius: 2px;
$link-background-color: lightblue;
$link-transition: background-color 0.2s ease-in-out;

nav {
  background: lightblue;
  padding: 1px;
  width: 100%;

  ul {
    margin: 1px;
    list-style: none;
    display: flex;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    
    li {
      a {
        color: $link-color;
        text-decoration: $link-decoration;
        font-size: $link-font-size;
        font-weight: $link-font-weight;
        padding: $link-padding;
        border-radius: $link-border-radius;
        background-color: $link-background-color;
        transition: $link-transition;
        height: 100%;

        &:hover {
          background-color: darken($link-background-color, 10%);
        }
      }
      
    }
    .version {
      font-size: 10px;
      color: grey;
      margin-left: 5px;
      align-self: flex-end;
    }
  }
}
