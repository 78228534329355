.input-page-container {
    height: 85vh;
    
    .editor-container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        height: 100%;
    }

    .button-container {
        display: flex;
        justify-content: flex-end;
    }
      
    .button {
        padding: 10px 20px;
        margin-right: 5%;
        background-color: #007bff;
        color: #fff;
        border: none;
        border-radius: 4px;
        font-size: 16px;
        cursor: pointer;
    }
      
    .button:hover {
        background-color: #0056b3;
    }
}

.wrapper {
    height: 90%;
    width: 90%;
}

.editor {
    background-color: whitesmoke;
}